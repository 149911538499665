<template>
  <div>
    <Navbar/>
    <div class="topo unidades-topo">
      <div class="container">
        <h1>Unidades</h1>
      </div>
    </div>
    <google-map/>
    <div class="conteudo">
        <div class="container cidades">
            <h1>Região Sudeste</h1>
            <hr>
            <div class="row justify-content-center">
                <div class="col-md-10">
                    <div class="row">
                        <div class="col-md-6 unidade">
                            <h2>São Paulo – SP</h2>
                            <p>Rua Dom Luis Felipe de Orleans, 1122 - Vila Maria (Baixa).<br>São Paulo/SP - CEP: 2118001</p>
                            <a href="https://www.google.com.br/maps/place/R.+Dom+Lu%C3%ADs+Felipe+de+Orleans,+1122+-+Vila+Maria+Baixa,+S%C3%A3o+Paulo+-+SP,+02118-001/@-23.5198188,-46.5883901,17z/data=!4m5!3m4!1s0x94ce5f31ab6dbac3:0xb2502b6dcaac5a84!8m2!3d-23.5198188!4d-46.5862014" target="_blank" rel="noopener noreferrer" class="btn btn-azul-escuro">Ver mapa</a>
                        </div>
                        <div class="col-md-6 unidade">
                            <h2>São Paulo – Suzano</h2>
                            <p>R. Prudente de Morais, 1802 - Vila Amorim.<br>Suzano/SP - CEP: 08613-135.</p>
                            <a href="https://www.google.com/maps/place/R.+Prudente+de+Morais,+1802+-+Vila+Amorim,+Suzano+-+SP,+08613-135/@-23.5392737,-46.2975487,17z/data=!3m1!4b1!4m5!3m4!1s0x94ce7a0f561ccf89:0x2326b9c1b5d3fc7d!8m2!3d-23.5392737!4d-46.29536" target="_blank" rel="noopener noreferrer" class="btn btn-azul-escuro">Ver mapa</a>
                        </div>

                        <div class="col-md-6 unidade">
                            <h2>São Paulo – Osasco</h2>
                            <p>R. Dr. José Augusto de Oliveira, 27 - Presidente Altino.<br>Osasco/SP - CEP: 06216-150.</p>
                            <a href="https://www.google.com/maps/place/R.+Dr.+Jos%C3%A9+Augusto+de+Oliveira,+27+-+Pres.+Altino,+Osasco+-+SP,+06216-150/@-23.5240182,-46.7747715,17z/data=!3m1!4b1!4m5!3m4!1s0x94ceff1c05ffe98f:0x99014f4f9bee5fc5!8m2!3d-23.5240182!4d-46.7725828" target="_blank" rel="noopener noreferrer" class="btn btn-azul-escuro">Ver mapa</a>
                        </div>
                        <div class="col-md-6 unidade">
                            <h2>São Paulo - SBC</h2>
                            <p>Av. Cézar Magnani, 645 – Paulicéia.<br>São Bernardo do Campo - SP, CEP: 09694-000.</p>
                            <a href="https://www.google.com/maps/place/Av.+C%C3%A9zar+Magnani,+645+-+Centro,+S%C3%A3o+Bernardo+do+Campo+-+SP/@-23.6689306,-46.5814651,17z/data=!3m1!4b1!4m5!3m4!1s0x94ce436e9b153a03:0xde9880fa973c6d7b!8m2!3d-23.6689306!4d-46.5792764" target="_blank" rel="noopener noreferrer" class="btn btn-azul-escuro">Ver mapa</a>
                        </div>

                        <div class="col-md-6 unidade">
                            <h2>São Paulo - Santo Amaro</h2>
                            <p>R. João de Paula Franco, 481 - Jardim Maraba.<br>São Paulo - SP, 04775-165</p>
                            <a href="https://www.google.com/maps/place/R.+Jo%C3%A3o+de+Paula+Franco,+481+-+Jardim+Maraba,+S%C3%A3o+Paulo+-+SP,+04775-165/@-23.6852519,-46.7011067,17z/data=!3m1!4b1!4m5!3m4!1s0x94ce4fd80f5f2451:0xb1d79d58d7a57fda!8m2!3d-23.6852519!4d-46.698918" target="_blank" rel="noopener noreferrer" class="btn btn-azul-escuro">Ver mapa</a>
                        </div>
                        <div class="col-md-6 unidade">
                            <h2>São Paulo - São Jose dos Campos</h2>
                            <p>R. Humaitá, 92 - Centro.<br>São José dos Campos/SP - CEP: 12245-810</p>
                            <a href="https://www.google.com/maps/place/R.+Humait%C3%A1,+92+-+Centro,+S%C3%A3o+Jos%C3%A9+dos+Campos+-+SP,+12245-810/@-23.1870621,-45.8896107,17z/data=!3m1!4b1!4m5!3m4!1s0x94cc4a16f55a68b9:0xefcb4bd9f6352bb2!8m2!3d-23.1870621!4d-45.887422" target="_blank" rel="noopener noreferrer" class="btn btn-azul-escuro">Ver mapa</a>
                        </div>

                        <div class="col-md-6 unidade">
                            <h2>São Paulo - Campinas</h2>
                            <p>Av. Ruy Rodrigues, 533 - Jardim Novo Campos Elíseos.<br>Campinas/SP. CEP: 13060-142</p>
                            <a href="https://www.google.com/maps/place/Av.+Ruy+Rodrigues,+533+-+Jardim+Novo+Campos+Eliseos,+Campinas+-+SP,+13060-142/@-22.9353166,-47.1076679,17z/data=!3m1!4b1!4m5!3m4!1s0x94c8c8506a3ada23:0x1c4fef7c42a1ce74!8m2!3d-22.9353166!4d-47.1054792" target="_blank" rel="noopener noreferrer" class="btn btn-azul-escuro">Ver mapa</a>
                        </div>
                        <div class="col-md-6 unidade">
                            <h2>São Paulo – Ribeirão Preto</h2>
                            <p>R. Coelho Neto, 461 - Campos Elísios.<br>Ribeirão Preto/SP - CEP: 14080-630</p>
                            <a href="https://www.google.com/maps/place/R.+Coelho+Neto,+461+-+Campos+El%C3%ADsios,+Ribeir%C3%A3o+Preto+-+SP,+14080-630/@-21.152915,-47.7963578,17z/data=!3m1!4b1!4m5!3m4!1s0x94b9bfb096db6125:0xbe865f1e23ae15d6!8m2!3d-21.152915!4d-47.7941691" target="_blank" rel="noopener noreferrer" class="btn btn-azul-escuro">Ver mapa</a>
                        </div>

                        <div class="col-md-6 unidade">
                            <h2>Minas Gerais – MG</h2>
                            <p>R. Rio Ganges, 689 - Novo Riacho <br>Contagem/MG - CEP: 32280-380</p>
                            <a href="https://www.google.com/maps/place/R.+Rio+Ganges,+689+-+Novo+Riacho,+Contagem+-+MG,+32280-380/@-19.9510194,-44.0639863,17z/data=!3m1!4b1!4m5!3m4!1s0xa6bfd43e60ec1d:0xa230d85fbabffe31!8m2!3d-19.9510194!4d-44.0617976" target="_blank" rel="noopener noreferrer" class="btn btn-azul-escuro">Ver mapa</a>
                        </div>
                        <div class="col-md-6 unidade">
                            <h2>Rio de Janeiro – RJ</h2>
                            <p>R. João Rodrigues, 12 - São Francisco Xavier <br>Rio de Janeiro – RJ/ CEP: 20960-010</p>
                            <a href="https://www.google.com/maps/place/R.+Jo%C3%A3o+Rodrigues,+12+-+S%C3%A3o+Francisco+Xavier,+Rio+de+Janeiro+-+RJ,+20960-010/@-22.9002673,-43.2452901,17z/data=!3m1!4b1!4m5!3m4!1s0x997e863ad3c9d1:0xa41a04dd4ea70aa!8m2!3d-22.9002673!4d-43.2431014" target="_blank" rel="noopener noreferrer" class="btn btn-azul-escuro">Ver mapa</a>
                        </div>

                        <div class="col-md-6 unidade">
                            <h2>Espírito Santo – ES</h2>
                            <p>R. Papagaio, 18 - Novo Horizonte. <br>Serra/ES - CEP: 29163-335</p>
                            <a href="https://www.google.com/maps/place/R.+Papagaio,+18+-+Novo+Horizonte,+Serra+-+ES,+29163-335/@-20.2178865,-40.2487987,17z/data=!3m1!4b1!4m5!3m4!1s0xb8194ab489d2fd:0xd9d054accf203a3c!8m2!3d-20.2178865!4d-40.24661" target="_blank" rel="noopener noreferrer" class="btn btn-azul-escuro">Ver mapa</a>
                        </div>
                    </div>
                </div>
            </div>
            <h1>Região Nordeste</h1>
            <hr>
            <div class="row justify-content-center">
                <div class="col-md-10">
                    <div class="row">
                        <div class="col-md-6 unidade">
                            <h2>Rio Grande do Norte – RN</h2>
                            <p>R. Machado de Assis, 1420 - Alecrim. <br>Natal/ RN – CEP: 59040-290</p>
                            <a href="https://www.google.com/maps/place/R.+Machado+de+Assis,+1420+-+Alecrim,+Natal+-+RN,+59040-290/@-5.7946625,-35.2230359,17z/data=!3m1!4b1!4m5!3m4!1s0x7b3aaa5e3eadff7:0xccfa0d42edfc8a4c!8m2!3d-5.7946625!4d-35.2208472" target="_blank" rel="noopener noreferrer" class="btn btn-azul-escuro">Ver mapa</a>
                        </div>
                        <div class="col-md-6 unidade">
                            <h2>Paraíba – PB</h2>
                            <p>Av. Centenário, 896 - Cruz das Armas. <br>João Pessoa/PB – CEP: 58085-300</p>
                            <a href="https://www.google.com/maps/place/Av.+Centen%C3%A1rio,+896+-+Cruz+das+Armas,+Jo%C3%A3o+Pessoa+-+PB,+58085-300/@-7.1440473,-34.8926121,17z/data=!3m1!4b1!4m5!3m4!1s0x7ace84406a77419:0xa11a7ffbd550b917!8m2!3d-7.1440473!4d-34.8904234" target="_blank" rel="noopener noreferrer" class="btn btn-azul-escuro">Ver mapa</a>
                        </div>

                        <div class="col-md-6 unidade">
                            <h2>Bahia – BA</h2>
                            <p>Rua Dr. Gerino de Souza Filho, 180 - Diamante. <br>Lauro de Freitas/ BA - CEP: 42700-000</p>
                            <a href="https://www.google.com/maps/place/R.+Dr.+Gerino+de+Souza+Filho,+180+-+Diamante,+Lauro+de+Freitas+-+BA,+42700-000/@-12.8921733,-38.3317543,17z/data=!3m1!4b1!4m5!3m4!1s0x716161047a4f419:0x3196af3b0cfeef28!8m2!3d-12.8921733!4d-38.3295656" target="_blank" rel="noopener noreferrer" class="btn btn-azul-escuro">Ver mapa</a>
                        </div>
                        <div class="col-md-6 unidade">
                            <h2>Pernambuco – PE</h2>
                            <p>Rua Imperial, 134 - São José. <br>Recife/PE – CEP: 50090-000</p>
                            <a href="https://www.google.com/maps/place/R.+Imperial,+134+-+S%C3%A3o+Jos%C3%A9,+Recife+-+PE,+50090-000/@-8.0721421,-34.8850765,17z/data=!3m1!4b1!4m5!3m4!1s0x7ab18b5bcefd8a1:0xc1ba23ccba16bc94!8m2!3d-8.0721421!4d-34.8828878" target="_blank" rel="noopener noreferrer" class="btn btn-azul-escuro">Ver mapa</a>
                        </div>

                        <div class="col-md-6 unidade">
                            <h2>Ceará – CE</h2>
                            <p>Rua Princesa Isabel, 1167 – Centro.<br>Fortaleza/CE, CEP: 60015-060</p>
                            <a href="https://www.google.com/maps/place/Rua+Princesa+Isabel,+1167+-+Centro,+Fortaleza+-+CE,+60015-060/@-3.7311294,-38.5389516,17z/data=!3m1!4b1!4m5!3m4!1s0x7c749a890d3b88d:0xe9c210478b47dd1e!8m2!3d-3.7311294!4d-38.5367629" target="_blank" rel="noopener noreferrer" class="btn btn-azul-escuro">Ver mapa</a>
                        </div>
                    </div>
                </div>
            </div>
            <h1>Região Sul</h1>
            <hr>
            <div class="row justify-content-center">
                <div class="col-md-10">
                    <div class="row">
                        <div class="col-md-6 unidade">
                            <h2>Rio Grande do Sul – RS</h2>
                            <p>Avenida Presidente Franklin Roosevelt, 1579 – São Geraldo. <br>Porto Alegre, Rio Grande do Sul/RS - CEP: 90230002</p>
                            <a href="https://www.google.com/maps/place/Av.+Pres.+Franklin+Roosevelt,+1579+-+S%C3%A3o+Geraldo,+Porto+Alegre+-+RS,+90230-002/@-30.0135553,-51.2067723,17z/data=!3m1!4b1!4m5!3m4!1s0x951979be197072c5:0x8106786bc307d80b!8m2!3d-30.0135553!4d-51.2045836" target="_blank" rel="noopener noreferrer" class="btn btn-azul-escuro">Ver mapa</a>
                        </div>
                        <div class="col-md-6 unidade">
                            <h2>Santa Catarina – SC</h2>
                            <p>Rua Nossa Senhora Aparecida, 701 – Jardim Eldorado. <br>Palhoça, Santa Catarina/SC.</p>
                            <a href="https://www.google.com/maps/place/R.+Nossa+Sra.+Aparecida,+701+-+Jardim+Eldorado,+Palho%C3%A7a+-+SC,+88133-400/@-27.6270048,-48.6637582,17z/data=!3m1!4b1!4m5!3m4!1s0x952735946260ecf1:0x3830e5455c6ff993!8m2!3d-27.6270048!4d-48.6615695" target="_blank" rel="noopener noreferrer" class="btn btn-azul-escuro">Ver mapa</a>
                        </div>

                        <div class="col-md-6 unidade">
                            <h2>Paraná – PR</h2>
                            <p>Rua Aristóteles da Silva Santos, 458 – Boqueirão. <br>Curitiba – Paraná/PR.</p>
                            <a href="https://www.google.com/maps/place/R.+Arist%C3%B3teles+da+Silva+Santos,+458+-+Boqueir%C3%A3o,+Curitiba+-+PR,+81650-160/@-25.4973532,-49.2282514,17z/data=!3m1!4b1!4m5!3m4!1s0x94dcfaeba1b03b57:0x7ad11fb64510f0b7!8m2!3d-25.4973532!4d-49.2260627" target="_blank" rel="noopener noreferrer" class="btn btn-azul-escuro">Ver mapa</a>
                        </div>
                    </div>
                </div>
            </div>
            <h1>Região Centro-Oeste</h1>
            <hr>
            <div class="row justify-content-center">
                <div class="col-md-10">
                    <div class="row">
                        <div class="col-md-6 unidade">
                            <h2>Goiás – GO</h2>
                            <p>R. C-159 QD 267 LOTE 07 S/N - Jardim América. <br>Goiânia – Goiás/GO.</p>
                            <a href="https://www.google.com/maps/search/R.+C-159+QD+267+LOTE+07+S%2FN+-+Jardim+Am%C3%A9rica.+Goi%C3%A2nia+%E2%80%93+Goi%C3%A1s%2FGO./@-16.7085918,-49.3019695,14z/data=!3m1!4b1" target="_blank" rel="noopener noreferrer" class="btn btn-azul-escuro">Ver mapa</a>
                        </div>
                        <div class="col-md-6 unidade">
                            <h2>Distrito Federal – DF</h2>
                            <p>A ADE CONJUNTO 24 LOTE 16 – Águas Claras. <br>Brasília – Distrito Federal/DF</p>
                            <a href="https://www.google.com/maps/place/16+-+SH+Arniqueiras+ADE+%C3%81guas+Claras+-+Taguatinga,+Bras%C3%ADlia+-+DF,+70297-400/@-15.8753398,-48.0029945,17z/data=!3m1!4b1!4m5!3m4!1s0x935a2e787788cd89:0xc4cadea7159574b4!8m2!3d-15.8753398!4d-48.0008058" target="_blank" rel="noopener noreferrer" class="btn btn-azul-escuro">Ver mapa</a>
                        </div>

                        <div class="col-md-6 unidade">
                            <h2>Mato Grosso do Sul – MS</h2>
                            <p>Rua Nefe Pael, 1809 - Nova Lima. <br>Campo Grande/MS - CEP: 79017-131</p>
                            <a href="https://www.google.com/maps/place/R.+Nefe+Pael,+1809+-+Nova+Lima,+Campo+Grande+-+MS,+79017-131/@-20.3950523,-54.5654872,17z/data=!3m1!4b1!4m5!3m4!1s0x9486e9d4f345bd69:0xc5ae91e8fe306699!8m2!3d-20.3950523!4d-54.5632985" target="_blank" rel="noopener noreferrer" class="btn btn-azul-escuro">Ver mapa</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- FOOTER -->
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import GoogleMap from '../components/GoogleMap.vue'

export default {
  name: 'Unidades',
  components: {
    Navbar,
    Footer,
    GoogleMap
  }
}
</script>