<template>
    <div>
        <gmap-map :center="center" :zoom="4" style="width:100%;  height: 400px;" class="hide-sm">
            <gmap-marker :key="index" v-for="(m, index) in markers" :position="m" @click="center=m"></gmap-marker>
        </gmap-map>
        <gmap-map :center="center" :zoom="3" style="width:100%;  height: 400px;" class="hide-md">
            <gmap-marker :key="index" v-for="(m, index) in markers" :position="m" @click="center=m"></gmap-marker>
        </gmap-map>
    </div>
</template>

<script>
    export default {
        name: "GoogleMap",
        data() {
            return {
                center: {
                    //lat: -17.1412458,
                    lat: -15.1389178,
                    lng: -53.1615832
                },
                markers: [],
                currentPlace: null
            };
        },

        mounted() {
            this.geolocate();
        },

        methods: {
            setPlace(place) {
                this.currentPlace = place;
            },
            geolocate: function () {
                navigator.geolocation.getCurrentPosition(position => {
                    this.center = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                });

                this.markers = [{
                        lat: -23.5198188,
                        lng: -46.5883901,
                        label: 'São Paulo - SP'
                    },
                    {
                        lat: -23.5392737,
                        lng: -46.2975487,
                        label: 'São Paulo - SP'
                    },
                    {
                        lat: -23.5240182,
                        lng: -46.7747715,
                        label: 'São Paulo - SP'
                    },
                    {
                        lat: -23.6689306,
                        lng: -46.5814651,
                        label: 'São Paulo - SP'
                    },
                    {
                        lat: -23.6852519,
                        lng: -46.7011067,
                        label: 'São Paulo - SP'
                    },
                    {
                        lat: -23.1870621,
                        lng: -45.8896107,
                        label: 'Sao jose dos campos - SP'
                    },
                    {
                        lat: -22.9353166,
                        lng: -47.1076679,
                        label: 'Campinas - SP'
                    },
                    {
                        lat: -21.152915,
                        lng: -47.7963578,
                        label: 'Ribeirão Preto - SP'
                    },
                    {
                        lat: -19.9510194,
                        lng: -44.0639863,
                        label: 'MG'
                    },
                    {
                        lat: -22.9002673,
                        lng: -43.2452901,
                        label: 'RJ'
                    },
                    {
                        lat: -20.2178865,
                        lng: -40.2487987,
                        label: 'ES'
                    },
                    {
                        lat: -5.7946625,
                        lng: -35.2230359,
                        label: 'RN'
                    },
                    {
                        lat: -7.1440473,
                        lng: -34.8926121,
                        label: 'PB'
                    },
                    {
                        lat: -12.8921733,
                        lng: -38.3317543,
                        label: 'BA'
                    },
                    {
                        lat: -8.0721421,
                        lng: -34.8850765,
                        label: 'PE'
                    },
                    {
                        lat: -3.7311294,
                        lng: -38.5389516,
                        label: 'CE'
                    },
                    {
                        lat: -30.0135553,
                        lng: -51.2067723,
                        label: 'RS'
                    },
                    {
                        lat: -27.6270048,
                        lng: -48.6637582,
                        label: 'SC'
                    },
                    {
                        lat: -25.4973532,
                        lng: -49.2282514,
                        label: 'PR'
                    },
                    {
                        lat: -16.7085918,
                        lng: -49.3019695,
                        label: 'GO'
                    },
                    {
                        lat: -15.8753398,
                        lng: -48.0029945,
                        label: 'DF'
                    },
                    {
                        lat: -20.3950523,
                        lng: -54.5654872,
                        label: 'MS'
                    }
                ];

            }
        }
    };
</script>